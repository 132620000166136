import { IUser } from 'src/types/auth';
import { ICustomer } from 'src/types/customer';
import { IRole } from 'src/types/role';
import { IOrderInfo, IUserPatch } from 'src/types/user';
import ClientBase from './base';

export interface ClientMyAccountMix {
  getMe: () => Promise<IUser>;
  logout: () => Promise<any>;
  updatePassword: (userId: string, password: string, newPassword: string) => Promise<any>;

  getMyCustomers: (productId?: string) => Promise<ICustomer[]>;
  getMyOrders: (customerId: string) => Promise<IOrderInfo[]>;
  getMyOrderRoles: (orderId: string) => Promise<IRole>;
  patchUser: (userId: string, data: IUserPatch) => Promise<IUser>;
}

const ClientMyAccount = <TBase extends Constructor<ClientBase>>(superclass: TBase) =>
  class extends superclass implements ClientMyAccountMix {
    getMe = async () => {
      return this.doFetch<IUser>(`${this.getBaseRoute()}/users/me`, {
        method: 'get',
      });
    };

    logout = async () => {
      return this.doFetch(`${this.getBaseRoute()}/users/logout`, {
        method: 'post',
        data: null,
      });
    };

    updatePassword = async (userId: string, password: string, newPassword: string) => {
      return this.doFetch<any>(`${this.getBaseRoute()}/${userId}/password`, {
        method: 'PUT',
        data: { password, newPassword },
      });
    };

    getMyCustomers = async (productId = '6466f6837a0b85961bb17076') => {
      return this.doFetch<ICustomer[]>(`${this.getBaseRoute()}/product/${productId}/customers`, {
        method: 'GET',
      });
    };

    getMyOrders = async (customerId: string) => {
      return this.doFetch<IOrderInfo[]>(
        `${this.getBaseRoute()}/customers/${customerId}/orders/all`,
        {
          method: 'GET',
        },
      );
    };

    getMyOrderRoles = async (orderId: string) => {
      return this.doFetch<IRole>(`${this.getBaseRoute()}/orders/${orderId}/roles`, {
        method: 'GET',
      });
    };

    patchUser = async (userId: string, data: IUserPatch) => {
      return this.doFetch<IUser>(`${this.getBaseRoute()}/users/${userId}`, {
        method: 'PATCH',
        data: data,
      });
    };
  };

export default ClientMyAccount;
