import { io } from 'socket.io-client';
import _ from 'lodash';

import config from 'src/config';
import store from 'src/redux_store';
import {
  addNewNotificationItem,
  // changeTotalPageAndTotalData,
  deleteNotificationLocal,
  setNumberAndInfoOfNotification,
} from 'src/redux_store/notification/notification_slice';
import { INotification } from 'src/types/notification';

import { markAllNotification, markItemNotification } from 'src/utils/notification';

import { logoutLocal } from 'src/redux_store/my_account/my_account_slice';
import { changeCameraStatus } from 'src/redux_store/camera/camera_slice';
import { PATH } from 'src/routes/path';
import { ECameraStatus } from 'src/types/camera';

const RECONNECTION_ATTEMPTS = 10;
const RECONNECTION_DELAY = 1000;
const RECONNECTION_DELAY_MAX = 5000;

class WebsocketClient {
  [x: string]: any;
  constructor() {
    this.io = null;
  }

  initialize(userToken: string) {
    this.io = io(config.baseUrl, {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: RECONNECTION_ATTEMPTS,
      reconnectionDelay: RECONNECTION_DELAY,
      reconnectionDelayMax: RECONNECTION_DELAY_MAX,
      auth: {
        token: userToken,
      },
    });

    this.listen();
  }

  listen() {
    this.io.on('notification', (data: INotification) => {
      const { notificationSlice, topBarSlice } = store.getState();
      const { numberOfNotifications, payload } = notificationSlice;
      const { openRightContent, typeContentShow } = topBarSlice;
      const dispatch = store.dispatch;

      const { pathname } = window.location;

      const newNumberOfNotifications = numberOfNotifications + 1;
      // dispatch(changeTotalPageAndTotalData('plus'));
      if (
        pathname === PATH.notification ||
        (openRightContent && typeContentShow === 'notification')
      ) {
        dispatch(
          setNumberAndInfoOfNotification({
            numberNotification: 0,
            data,
          }),
        );
        if (_.isEmpty(payload.placeOfOrigin) && !payload.type) {
          dispatch(addNewNotificationItem(data));
        }
      } else {
        dispatch(
          setNumberAndInfoOfNotification({
            numberNotification: newNumberOfNotifications,
            data,
          }),
        );
      }
    });

    this.io.on('setNumberOfNotificationsToZero', () => {
      console.log('setNumberOfNotificationsToZero');
      const dispatch = store.dispatch;

      dispatch(
        setNumberAndInfoOfNotification({
          numberNotification: 0,
          data: {} as INotification,
        }),
      );
    });

    this.io.on('syncReadNotification', (data: { notificationId: string }) => {
      const { notificationId } = data;
      const dispatch = store.dispatch;
      console.log({ notificationId });

      const index = returnIndexNotificationItem(notificationId);
      if (index !== -1) {
        markItemNotification(dispatch, 'read', notificationId, true);
      }
    });

    this.io.on('syncAllReadNotifications', () => {
      const dispatch = store.dispatch;

      markAllNotification(dispatch, true);
    });

    this.io.on('syncUnreadNotification', (data: { notificationId: string }) => {
      const dispatch = store.dispatch;

      const { notificationId } = data;

      const index = returnIndexNotificationItem(notificationId);

      if (index !== -1) {
        markItemNotification(dispatch, 'unread', notificationId, true);
      }
    });

    // this.io.on('syncTotalUnseenNotificationsToZero', (data: any) => {
    //   // console.log('syncTotalUnseenNotificationsToZero ==> ', data);
    // });

    this.io.on('syncDeletedNotification', (data: { notificationId: string }) => {
      const { notificationId } = data;
      const index = returnIndexNotificationItem(notificationId);
      const dispatch = store.dispatch;

      if (index !== -1) {
        dispatch(deleteNotificationLocal(notificationId));
      }
    });

    this.io.on('clearSession', () => {
      const dispatch = store.dispatch;

      // dispatch(logout());
      dispatch(logoutLocal());
    });

    this.io.on('changeCameraStatus', (data: { cameraId: string; status: ECameraStatus }) => {
      const dispatch = store.dispatch;

      dispatch(changeCameraStatus(data));
    });
  }

  syncNumberOfNotification(userId: string) {
    this.io.emit('syncNumberOfNotification', userId);
  }

  disconnect() {
    if (this.io) {
      this.io.disconnect();
    }
  }
}

const returnIndexNotificationItem = (id: string) => {
  const { notificationSlice } = store.getState();
  const { notificationList } = notificationSlice;

  const index = notificationList.findIndex((item) => item.id === id);
  console.log({ notificationList, id, index });

  return index;
};

export default new WebsocketClient();
